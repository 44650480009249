import genModel from "@/share/components/libs/genModel";

export default {
  mixins: [genModel],
  data() {
    return {
      contentHeight: 0,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize, true);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize, true);
  },
  computed: {
    getUrl() {
      return this.$root.api[this.$route.params.api];
    },
    model() {
      this.$root.appName = this.getConfigView(this.viewName).name;
      return this.modelIndex;
    },
  },

  methods: {
    onResize() {
      let height = window.innerHeight;
      this.contentHeight = height - 152;
    },
    reloadData() {
      this.$refs.table.updateData();
    },
  },
};